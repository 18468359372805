// firebase
import { initializeApp } from "firebase/app";

// Initialize Firebase
const app = initializeApp({
  apiKey: "AIzaSyDKVI15fPSMcuPb48At_55CNODPG1uN-OA",
  authDomain: "gabo534.firebaseapp.com",
  projectId: "gabo534",
  storageBucket: "gabo534.appspot.com",
  messagingSenderId: "45612275350",
  appId: "1:45612275350:web:a0f226429bf34d9cc594b7",
  measurementId: "G-G9BRQJ2BHK"
});

export default app;